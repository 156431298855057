import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, LocaleProvider } from "antd";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import appActions from "../../redux/app/actions";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import ThemeSwitcher from "../../containers/ThemeSwitcher";
import { Route } from "react-router-dom";
import { AppLocale } from "../../dashApp";
import themes from "../../settings/themes";
import AppHolder from "./commonStyle";
import "./global.css";
import asyncComponent from "../../helpers/AsyncFunc";
import ZendeskProvider from "../../components/zendeskContext.js";

const { Content } = Layout;
const { toggleAll } = appActions;

export class App extends Component {
    componentDidMount() {
        const isLoggedIn = localStorage.getItem("app");
        // const path = this.props.location.pathname;
        if (!isLoggedIn) {
            // if User is not loggedin redirects to signin
            this.props.history.push("/signin");
        } else {
            // rmRedirectTo();//
        }

        // if (
        //   path !== "/cmp/profileSettings" &&
        //   path !== "/cmp/changePassword" &&
        //   path !== "/cmp/accounts"
        // ) {
        //   this.props.history.push("/404");
        // }
    }

    render() {
        const { url } = this.props.match;
        const pathname = this.props.location.pathname;
        const { locale, selectedTheme } = this.props;
        const currentAppLocale = AppLocale[locale];

        return (
            <LocaleProvider locale={currentAppLocale.antd}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <ThemeProvider theme={themes[selectedTheme]}>
                        <ZendeskProvider>
                            <AppHolder>
                                <Layout>
                                    {/* <Debounce time="1000" handler="onResize">
                                    <WindowResizeListener
                                        onResize={windowSize =>
                                            this.props.toggleAll(
                                                windowSize.windowWidth,
                                                windowSize.windowHeight
                                            )
                                        }
                                    />
                                </Debounce> */}
                                    <Topbar url={url} />
                                    <Layout
                                        style={{
                                            flexDirection: "row",
                                            overflowX: "hidden"
                                        }}
                                    >
                                        <Sidebar
                                            url={url}
                                            pathname={pathname}
                                        />
                                        <Layout className="isoContentMainLayout">
                                            <Content
                                                className="isomorphicContent"
                                                style={{
                                                    flexShrink: "0",
                                                    background: "#f1f3f6",
                                                    position: "relative"
                                                }}
                                            >
                                                <Route
                                                    exact
                                                    path={"/cmp/dashboard"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../AccountDashboard/AccountDashboard")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/configuration"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Configuration/Configuration")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/users"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../AccountUsers/AccountUsers")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/docs"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Documentation/Documentation")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/docs/:docId"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Documentation/Documentation")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/accounts"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Accounts/Accounts")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/changePassword"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../ProfileSettings/ChangePassword")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={
                                                        "/cmp/profileSettings"
                                                    }
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../ProfileSettings/ProfileSettings")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/export"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Export/Export")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/adm"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Adm/Adm")
                                                    )}
                                                />
                                                <Route
                                                    exact
                                                    path={"/cmp/removeusers"}
                                                    component={asyncComponent(
                                                        () =>
                                                            import("../Adm/RemoveUsers/RemoveUsers")
                                                    )}
                                                />
                                            </Content>
                                        </Layout>
                                    </Layout>
                                    <ThemeSwitcher />
                                </Layout>
                            </AppHolder>
                        </ZendeskProvider>
                    </ThemeProvider>
                </IntlProvider>
            </LocaleProvider>
        );
    }
}

export default connect(
    state => ({
        auth: state.Auth,
        locale: state.LanguageSwitcher.language.locale,
        selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
        height: state.App.height
    }),
    { toggleAll }
)(App);
