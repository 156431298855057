const actions = {
    FETCH_DASHBOARD_DATA_SOURCE: "FETCH_DASHBOARD_DATA_SOURCE",
    SAVE_DATE_SELECTED: "SAVE_DATE_SELECTED",
    fetchDashboardDataSource: dataSource => ({
        type: actions.FETCH_DASHBOARD_DATA_SOURCE,
        dataSource
    }),
    saveDateSelected: (startDate, endDate) => ({
        type: actions.SAVE_DATE_SELECTED,
        startDate,
        endDate
    })
};

export default actions;
