import React from "react"; // eslint-disable-line
import { Provider } from "react-redux"; // eslint-disable-line
import { store, history } from "./redux/store";
import PublicRoutes from "./router"; // eslint-disable-line
import { ThemeProvider } from "styled-components"; // eslint-disable-line
import { LocaleProvider } from "antd"; // eslint-disable-line
import { IntlProvider } from "react-intl"; // eslint-disable-line
import themes from "./settings/themes";
import AppLocale from "./languageProvider";
import config, {
    getCurrentLanguage
} from "./containers/LanguageSwitcher/config";
import { themeConfig } from "./settings";
import DashAppHolder from "./dashAppStyle"; // eslint-disable-line

const currentAppLocale =
    AppLocale[getCurrentLanguage(config.defaultLanguage || "portugues").locale];

const DashApp = () => (
    <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
        >
            <ThemeProvider theme={themes[themeConfig.theme]}>
                <DashAppHolder>
                    <Provider store={store}>
                        <PublicRoutes history={history} />
                    </Provider>
                </DashAppHolder>
            </ThemeProvider>
        </IntlProvider>
    </LocaleProvider>
);

export default DashApp;
export { AppLocale };
