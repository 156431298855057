let baseApiUrl = "/api";

// if (process.env.NODE_ENV === "production") {
//     baseApiUrl = window.location.origin + "/api";
// }

const siteConfig = {
    siteName: "Tail CMP",
    siteWhiteLogo: "tail-white-logo",
    siteWhiteLogoCollapse: "tail-white-logo-collapse",
    siteIcon: "tail-icon"
};

const language = "portugues";

const themeConfig = {
    topbar: "themedefault",
    sidebar: "themedefault",
    layout: "themedefault",
    theme: "themedefault"
};

export { baseApiUrl, themeConfig, siteConfig, language };
