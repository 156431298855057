import actions from "./actions";
import cloneDeep from "lodash/cloneDeep";

const initState = {
    dataSource: [],
    startDate: "",
    endDate: ""
};

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.FETCH_DASHBOARD_DATA_SOURCE:
            return {
                ...state,
                dataSource: cloneDeep(action.dataSource)
            };
        case actions.SAVE_DATE_SELECTED:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate
            };
        default:
            return state;
    }
}
