import React, { Component } from "react";
import { connect } from "react-redux";
import clone from "clone";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import options from "./options";
import Menu from "../../components/uielements/menu";
import Spin from "../../components/uielements/spin";
import IntlMessages from "../../components/utility/intlMessages";
import SidebarWrapper from "./sidebar.style";
import appActions from "../../redux/app/actions";
import Logo from "../../components/utility/logo";
import isEmpty from "lodash/isEmpty";

import BakeryAPI from "../../bakeryAPI";
import AccountUsersActions from "../../redux/accountUsers/actions";
import SubMenu from "antd/lib/menu/SubMenu";

const { getUserApiStatus, getConsentApiStatus } = AccountUsersActions;

const { Sider } = Layout;

const SidebarComponent = ({ collapsed, children, height }) => (
    <SidebarWrapper>
        <Sider
            trigger={null}
            collapsible={true}
            collapsed={collapsed}
            width={240}
            className="isomorphicSidebar"
        >
            <Logo collapsed={collapsed} />
            {children}
        </Sider>
    </SidebarWrapper>
);
const {
    toggleOpenDrawer,
    changeOpenKeys,
    changeCurrent,
    toggleCollapsed
} = appActions;
const mountPermissionsArrayOfString = data => {
    let arrayOfPermissions = [];
    for (let i in data) {
        arrayOfPermissions.push(data[i].permission);
    }

    return arrayOfPermissions;
};

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.bakeryAPI = new BakeryAPI(this.props);

        this.state = {
            status: "",
            permissions: [],
            redirectToAccountPage: false,
            defaultOpenKeys: []
        };

        this.setMenuOption = this.setMenuOption.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        if (isEmpty(this.state.permissions)) {
            this.bakeryAPI
                .getPermissions()
                .then(res => {
                    this.setState({
                        permissions: mountPermissionsArrayOfString(res.data)
                    });
                })
                .catch(res => console.log(res, "catch"));
        }

        this.setMenuOption();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.pathname !== this.props.pathname) {
            if (
                isEmpty(this.state.permissions) ||
                this.props.pathname === "/cmp/dashboard"
            ) {
                this.bakeryAPI
                    .getPermissions()
                    .then(res => {
                        this.setState({
                            permissions: mountPermissionsArrayOfString(res.data)
                        });
                    })
                    .catch(res => console.log(res, "catch"));
            }

            this.setMenuOption();
        }
    }

    setMenuOption() {
        if (this.props.pathname.search("/removeusers") !== -1) {
            this.setState({
                defaultOpenKeys: ["adm-parent"]
            });
            this.props.changeCurrent(["removeusers"]);
            return;
        }
        if (this.props.pathname.search("/adm") !== -1) {
            this.setState({
                defaultOpenKeys: ["adm-parent"]
            });
            this.props.changeCurrent(["adm"]);
            return;
        }
        this.setState({
            defaultOpenKeys: []
        });

        for (let idx in options) {
            if (this.props.pathname.endsWith(options[idx].key)) {
                this.props.changeCurrent([options[idx].key]);
                return;
            }
        }

        if (this.props.pathname.search("/docs/") !== -1) {
            this.props.changeCurrent(["docs"]);
            return;
        }

        if (this.props.pathname.search("/profileSettings") !== -1) {
            this.props.changeCurrent(["profileSettings"]);
            return;
        }

        this.props.changeCurrent(["dashboard"]);
    }

    handleClick(e) {
        this.props.changeCurrent([e.key]);
        if (this.props.app.view === "MobileView") {
            setTimeout(() => {
                this.props.toggleCollapsed();
                this.props.toggleOpenDrawer();
            }, 100);
        }
    }

    getMenuItem = ({ singleOption }, permissions) => {
        // const accountUid = this.bakeryAPI.getAccountUid();

        const shouldEscapeUsers =
            !permissions.includes("ACCOUNT_USERS") &&
            singleOption.key === "users";
        const shouldEscapeConfig =
            !permissions.includes("CONSENT_MANAGER_UPDATE") &&
            singleOption.key === "configuration";

        const shouldEscapeAdm =
            !permissions.includes("GLOBAL_SUPPORT_ACCOUNT_BAKERY_LIST_ALL") &&
            !permissions.includes("GLOBAL_ADMIN_USER_DELETE") &&
            singleOption.key === "adm-parent";

        if (shouldEscapeUsers || shouldEscapeConfig || shouldEscapeAdm) {
            return;
        }

        if (singleOption.children) {
            return (
                <SubMenu
                    key={singleOption.key}
                    // onClick={this.handleClick}
                    title={
                        <span className="isoMenuHolder">
                            <i className={singleOption.leftIcon} />
                            <span className="nav-text">
                                <IntlMessages id={singleOption.label} />
                            </span>
                        </span>
                    }
                >
                    {singleOption.children.map((item, idx) => {
                        if (!permissions.includes(item.role)) {
                            return null;
                        }
                        return (
                            <Menu.Item key={item.key}>
                                <Link
                                    to={`/cmp/${item.key}`}
                                    style={{
                                        marginLeft: "-40px"
                                    }}
                                >
                                    <i
                                        className={item.leftIcon}
                                        style={{
                                            marginRight: "10px"
                                        }}
                                    />

                                    <IntlMessages id={item.label} />
                                </Link>
                            </Menu.Item>
                        );
                    })}
                </SubMenu>
            );
        } else {
            return (
                <Menu.Item key={singleOption.key}>
                    <Link to={`/cmp/${singleOption.key}`}>
                        <span className="isoMenuHolder">
                            <i className={singleOption.leftIcon} />
                            <span className="nav-text">
                                <IntlMessages id={singleOption.label} />
                            </span>
                        </span>
                    </Link>
                </Menu.Item>
            );
        }
    };

    render() {
        const { app } = this.props;
        const collapsed = clone(app.collapsed) && !clone(app.openDrawer);
        const mode = collapsed === true ? "vertical" : "inline";

        let filteredOptions = options.filter(option => {
            const hasAccountUid =
                localStorage.getItem("accountUid") &&
                localStorage.getItem("accountUid").length > 0;

            return option.parent !== "accounts" || hasAccountUid;
        });
        // TODO refactor its causing memory leaking
        if (
            this.props.getUserApiIsFecthing ||
            this.props.getConsentApiIsFecthing
        ) {
            return (
                <SidebarComponent collapsed={collapsed}>
                    <Spin />
                </SidebarComponent>
            );
        }

        return (
            <SidebarComponent collapsed={collapsed}>
                <Menu
                    onClick={this.handleClick}
                    theme="dark"
                    className="isoDashboardMenu"
                    defaultOpenKeys={this.state.defaultOpenKeys}
                    // openKeys={
                    //     this.state.defaultOpenKeys
                    //         ? this.state.defaultOpenKeys
                    //         : null
                    // }
                    mode={mode}
                    // openKeys={this.state.defaultOpenKeys}
                    selectedKeys={app.current}
                >
                    {filteredOptions.map(singleOption =>
                        this.getMenuItem(
                            { singleOption },
                            this.state.permissions
                        )
                    )}
                </Menu>
            </SidebarComponent>
        );
    }
}

export default connect(
    state => ({
        app: state.App,
        customizedTheme: state.ThemeSwitcher.sidebarTheme,
        height: state.App.height,
        getUsersApiStatus: state.AccountUsers.getUsersApiStatus,
        consentApiStatus: state.AccountUsers.consentApiStatus,
        getUserApiIsFecthing: state.AccountUsers.getUserApiIsFecthing,
        getConsentApiIsFecthing: state.AccountUsers.getConsentApiIsFecthing
    }),
    {
        toggleOpenDrawer,
        changeOpenKeys,
        changeCurrent,
        toggleCollapsed,
        getUserApiStatus,
        getConsentApiStatus
    }
)(Sidebar);
