import { handleToken, clearToken } from "./helpers/utility";
import { baseApiUrl } from "./settings";
import { getRedirectTo, setRedirectTo } from "./helpers/utility";

class BakeryAPI {
    constructor(props) {
        this.props = props;
    }

    //Authentication Data
    getJwt() {
        return localStorage.getItem("app")
            ? JSON.parse(localStorage.getItem("app")).jwt
            : "";
    }

    getAccountUid() {
        return localStorage.getItem("accountUid");
    }

    // USER
    login(email, password) {
        return (async () => {
            const body = {
                email,
                password
            };

            let content;
            try {
                content = await this.apiCall("/user/login", "POST", body);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    mfa(code, jwt, email) {
        return (async () => {
            const body = {
                jwt,
                code
            };

            let content;
            try {
                content = await this.apiCall("/user/mfa", "POST", body);
            } catch (err) {
                console.log(err);
            }

            handleToken(content, email);

            return content;
        })();
    }

    resendMfaEmail(email) {
        return (async () => {
            const body = {
                email
            };

            let content;
            try {
                content = await this.apiCall(
                    "/user/mfa/emailcode",
                    "POST",
                    body
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    getMfaKey(jwt) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall("/user/mfa/key", "GET", "", jwt);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    setMfaKey(mfaKey, mfaCode, jwt) {
        return (async () => {
            const body = {
                mfaKey,
                code: mfaCode
            };

            let content;
            try {
                content = await this.apiCall(
                    "/user/mfa/key",
                    "POST",
                    body,
                    jwt
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    disableMfa(jwt) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall(
                    "/user/mfa/key",
                    "DELETE",
                    "",
                    jwt
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    signUpNewUser(name, email, password, grantToken) {
        return (async () => {
            const body = {
                name,
                email,
                password,
                grantToken
            };

            let content;
            try {
                content = await this.apiCall("/user/signup", "POST", body);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    logout(jwt) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall("/user/logout", "GET", "", jwt);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    getProfile(jwt) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall("/user/profile", "GET", "", jwt);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    resetPasswordRequest(email) {
        return (async () => {
            const body = {
                email
            };

            let content;
            try {
                content = await this.apiCall(
                    "/user/password/reset/request",
                    "POST",
                    body
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    resetPassword(email, password, token) {
        return (async () => {
            const body = {
                email,
                password,
                token
            };

            let content;
            try {
                content = await this.apiCall(
                    "/user/password/reset",
                    "POST",
                    body
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    confirmEmail(token) {
        return (async () => {
            const body = {
                token
            };

            let content;
            try {
                content = await this.apiCall(
                    "/user/email/confirmation",
                    "POST",
                    body
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    changePassword(password, jwt) {
        return (async () => {
            const body = {
                password
            };

            let content;
            try {
                content = await this.apiCall(
                    "/user/password",
                    "POST",
                    body,
                    jwt
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    // SECURITY

    getPolicies() {
        return (async () => {
            const content = await this.apiCall("/password/policies");

            return content;
        })();
    }

    getRoles(jwt) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall("/roles", "GET", "", jwt);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    getPermissions() {
        return (async () => {
            let content;
            try {
                content = await this.apiCall(
                    "/permissions",
                    "GET",
                    "",
                    this.getJwt(),
                    "",
                    this.getAccountUid()
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    // ACCOUNT

    getAccounts(jwt, accountUid) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall(
                    "/account",
                    "GET",
                    "",
                    jwt,
                    "",
                    accountUid
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }
    getAllAccounts(page, limit, name) {
        return (async () => {
            return await this.apiCall(
                `/accounts?page=${page}&limit=${limit}&name=${name}`,
                "GET",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    editAccount(body) {
        return (async () => {
            return await this.apiCall(
                "/account",
                "PUT",
                body,
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    createNewAccount(name, jwt) {
        return (async () => {
            const body = {
                name
            };

            let content;
            try {
                content = await this.apiCall("/account", "POST", body, jwt);
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    updateAccountName(name, jwt, accountUid) {
        return (async () => {
            const body = {
                name
            };

            let content;
            try {
                content = await this.apiCall(
                    "/account",
                    "PATCH",
                    body,
                    jwt,
                    "",
                    accountUid
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    getUsersByAccount(jwt, accountUid) {
        return (async () => {
            let content;
            try {
                content = await this.apiCall(
                    "/account/users",
                    "GET",
                    "",
                    jwt,
                    "",
                    accountUid
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    grantAccess(email, role, jwt, accountUid) {
        return (async () => {
            const body = {
                email,
                role
            };

            let content;
            try {
                content = await this.apiCall(
                    "/account/grant",
                    "POST",
                    body,
                    jwt,
                    "",
                    accountUid
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    revokeAccess(email, jwt, accountUid) {
        return (async () => {
            const body = {
                email
            };

            let content;
            try {
                content = await this.apiCall(
                    "/account/revoke",
                    "POST",
                    body,
                    jwt,
                    "",
                    accountUid
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    // CONSENT MANAGER
    getConsentJson() {
        return (async () => {
            return await this.apiCall(
                "/consentmanager",
                "GET",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    saveConsentJson(body) {
        return (async () => {
            return await this.apiCall(
                "/consentmanager",
                "PUT",
                body,
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    publishConsentJson() {
        return (async () => {
            return await this.apiCall(
                "/consentmanager/publish",
                "GET",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    retrieveTailCmpJs() {
        return baseApiUrl + "/consentmanager/tailcmp.js";
    }

    getDashboardData(startDate, endDate) {
        if (!startDate) {
            startDate = "";
        }

        if (!endDate) {
            endDate = "";
        }
        return (async () => {
            let content;
            try {
                content = await this.apiCall(
                    `/consentmanager/stats?from=${startDate}&to=${endDate}`,
                    "GET",
                    "",
                    this.getJwt(),
                    "",
                    this.getAccountUid()
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    oauthApp(client_id, redirect_uri, grant_type) {
        return (async () => {
            const body = {
                client_id,
                redirect_uri,
                grant_type
            };

            let content;
            try {
                content = await this.apiCall(
                    "/oauth/app",
                    "POST",
                    body,
                    this.getJwt(),
                    "",
                    ""
                );
            } catch (err) {
                console.log(err);
            }

            return content;
        })();
    }

    //EXPORT
    saveExportConfigJson(body) {
        return (async () => {
            return await this.apiCall(
                "/integration/cdp ",
                "POST",
                body,
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    getExportConfigJson(body) {
        return (async () => {
            return await this.apiCall(
                "/integration/cdp ",
                "GET",
                body,
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }
    getExportHistory(page, limit, startDate, endDate, status) {
        let path = `/export-histories?page=${page}&limit=${limit}`;
        if (startDate) {
            path += `&from=${startDate}`;
        }
        if (endDate) {
            path += `&to=${endDate}`;
        }
        if (status) {
            path += `&status=${status}`;
        }

        return (async () => {
            return await this.apiCall(
                path,
                "GET",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    //Active sessions
    getActiveSessions() {
        return (async () => {
            return await this.apiCall(
                `/user/sessions`,
                "GET",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    deleteActiveSession(id) {
        return (async () => {
            return await this.apiCall(
                `/user/sessions?tokenId=${id}`,
                "DELETE",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }
    //REMOVE USERS
    getUsersByEMail(body) {
        return (async () => {
            return await this.apiCall(
                `/admin/user/list`,
                "POST",
                body,
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    getAccountsByEMail(body) {
        return (async () => {
            return await this.apiCall(
                `/admin/user/accounts`,
                "POST",
                body,
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    deleteUserByEmail(email) {
        return (async () => {
            return await this.apiCall(
                `/admin/user?email=${encodeURIComponent(email)}`,
                "DELETE",
                "",
                this.getJwt(),
                "",
                this.getAccountUid()
            );
        })();
    }

    isOnSignin() {
        return window.location.pathname.indexOf("/signin") > -1;
    }

    // GENERAL
    apiCall(endpoint, method = "GET", body, jwt, uid, accountUid) {
        return (async () => {
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json",
                uid,
                AccountUid: accountUid
            };

            let bodyMap = {
                method,
                headers,
                credentials: "same-origin"
            };

            if (method === "POST" || method === "PATCH" || method === "PUT") {
                bodyMap = {
                    method,
                    headers,
                    body: JSON.stringify(body)
                };
            }

            const response = await fetch(baseApiUrl + endpoint, bodyMap);

            let hasRedirectTo = null;

            try {
                hasRedirectTo = getRedirectTo();
            } catch (e) {}

            if (response && response.status === 401) {
                if (!hasRedirectTo) {
                    //save url to redirect after login
                    setRedirectTo(window.location.pathname);
                }

                clearToken();
                if (!this.isOnSignin()) {
                    //case of oauth, must get the exact params of url
                    this.props.history.push(
                        `/signin?redirect=` +
                            encodeURIComponent(window.location.href)
                    );
                }
            }

            const content = await response.json();

            return content;
        })();
    }
}

export default BakeryAPI;
