import App from "./app/reducer";
import ThemeSwitcher from "./themeSwitcher/reducer";
import LanguageSwitcher from "./languageSwitcher/reducer";
import AccountUsers from "./accountUsers/reducer";
import Dashboard from "./dashboard/reducer";

export default {
    App,
    ThemeSwitcher,
    LanguageSwitcher,
    AccountUsers,
    Dashboard
};
