import React, { Component } from "react";
import { connect } from "react-redux";
import actions from "../../redux/languageSwitcher/actions";
import config from "./config";
import { Dropdown } from "antd";
import { DropdownMenu, MenuItem } from "../../components/uielements/dropdown";

import { setLangOnButton } from "../../helpers/utility";

const { changeLanguage } = actions;

const flagBaseStyle = {
    cursor: "pointer",
    margin: 5,
    border: "none",
    width: 25,
    height: 25,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
};

class LanguageSwitcher extends Component {
    render() {
        const { changeLanguage } = this.props;

        const menuPreviewLangs = (
            <DropdownMenu>
                {config.options.map(option => (
                    <MenuItem
                        key={option.languageId}
                        onClick={() => {
                            console.log("LanguageSwitcher : ", option);
                            //set lang on zendesk
                            setLangOnButton(option.locale);
                            //chnage lang on all app
                            changeLanguage(option.languageId);
                        }}
                    >
                        <div
                            style={Object.assign({}, flagBaseStyle, {
                                backgroundImage: `url(${option.icon})`
                            })}
                        />
                    </MenuItem>
                ))}
            </DropdownMenu>
        );

        return (
            <div className="themeSwitchBlock">
                <div className="themeSwitchBtnWrapper">
                    <Dropdown overlay={menuPreviewLangs}>
                        <div
                            style={Object.assign({}, flagBaseStyle, {
                                backgroundImage: `url(${
                                    this.props.language.icon
                                })`
                            })}
                        />
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default connect(
    state => ({
        ...state.LanguageSwitcher
    }),
    { changeLanguage }
)(LanguageSwitcher);
