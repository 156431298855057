import React, { Component } from "react";
import Popover from "../../components/uielements/popover";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

class TopbarAccounts extends Component {
    constructor(props) {
        super(props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.state = {
            visible: false
        };
    }

    handleVisibleChange() {
        this.setState({ visible: !this.state.visible });
    }

    handleSetAccount(account) {
        this.props.saveAccountId(account);
        this.setState({ visible: false });
    }

    render() {
        const { accounts, accountSeleted } = this.props;

        let content = null;

        if (accounts) {
            content = (
                <TopbarDropdownWrapper className="isoUserDropdown">
                    {accounts.map((account, idx) => (
                        <div
                            key={
                                account && account.hasOwnProperty("uid")
                                    ? account.uid
                                    : `ac-${idx}`
                            }
                            onClick={() => this.handleSetAccount(account)}
                        >
                            <Link
                                className="isoDropdownLink"
                                to={`/cmp/dashboard`}
                            >
                                {account && account.hasOwnProperty("name")
                                    ? account.name
                                    : ""}
                            </Link>
                        </div>
                    ))}
                </TopbarDropdownWrapper>
            );
        }

        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter={true}
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    {accountSeleted && (
                        <b>
                            {accountSeleted.name} - [{accountSeleted.uid}]
                        </b>
                    )}
                </div>
            </Popover>
        );
    }
}
export default withRouter(TopbarAccounts);
