const options = [
    {
        parent: "accounts",
        key: "dashboard",
        label: "sidebar.dashboard",
        leftIcon: "ion-podium"
    },
    {
        parent: "accounts",
        key: "configuration",
        label: "sidebar.configuration",
        leftIcon: "ion-gear-a"
    },
    {
        parent: "accounts",
        key: "users",
        label: "sidebar.users",
        leftIcon: "ion-android-contacts"
    },
    {
        key: "accounts",
        label: "sidebar.accounts",
        leftIcon: "ion-grid"
    },
    {
        key: "docs",
        label: "sidebar.documentation",
        leftIcon: "ion-android-document"
    },
    {
        parent: "accounts",
        key: "export",
        label: "sidebar.export",
        leftIcon: "ion-android-upload"
    },
    {
        key: "adm-parent",
        label: "sidebar.adm",
        leftIcon: "ion-ios-people",
        children: [
            {
                key: "adm",
                label: "sidebar.adm.manageaccounts",
                leftIcon: "ion-ios-people",
                role: "GLOBAL_SUPPORT_ACCOUNT_BAKERY_LIST_ALL"
            },
            {
                key: "removeusers",
                label: "sidebar.adm.removeusers",
                leftIcon: "ion-android-remove",
                role: "GLOBAL_ADMIN_USER_DELETE"
            }
        ]
    }
];
export default options;
