import { language } from "../../settings";

const config = {
    defaultLanguage: language,
    options: [
        {
            languageId: "portugues",
            locale: "pt",
            text: "Portugues",
            icon: "/images/flag/pt.png"
        },{
            languageId: "english",
            locale: "en",
            text: "English",
            icon: "/images/flag/en.png"
       /* },{
            languageId: "espanol",
            locale: "es",
            text: "Español",
            icon: "/images/flag/es.png"*/
        }
    ]
};

export function getCurrentLanguage(lang) {
    let selecetedLanguage = config.options[0];
    config.options.forEach(language => {
        if (language.languageId === lang) {
            selecetedLanguage = language;
        }
    });
    return selecetedLanguage;
}
export default config;
