import React from "react";
import { Link } from "react-router-dom";
import { siteConfig } from "../../settings";

export default ({ collapsed }) => {
    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div>
                    <h3>
                        <Link to="/cmp/accounts">
                            <i className={siteConfig.siteWhiteLogoCollapse} />
                        </Link>
                    </h3>
                </div>
            ) : (
                <h3>
                    <Link to="/cmp/accounts">
                        <div>
                            <i className={siteConfig.siteWhiteLogo} />
                        </div>
                    </Link>
                </h3>
            )}
        </div>
    );
};
