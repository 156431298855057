import React, { Component } from "react";
import Nprogress from "nprogress";
import ReactPlaceholder from "react-placeholder";
import "nprogress/nprogress.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { closeZendesk } from "./utility";

export default function asyncComponent(importComponent) {
    class AsyncFunc extends Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }
        componentWillMount() {
            Nprogress.start();
        }
        componentWillUnmount() {
            this.mounted = false;
        }

        closeZendeskApp(props) {
            if (props.match && props.match.path) {
                let _path = props.match.path;

                //all logged areas has /cmp
                let pathMustClose = "/cmp";

                //if your in a area tha is not logged, close zendesk App
                if (_path.indexOf(pathMustClose) === -1) {
                    //close Sendesk support
                    closeZendesk();
                }
            }
        }

        async componentDidMount() {
            this.mounted = true;
            const { default: Component } = await importComponent();
            Nprogress.done();
            if (this.mounted) {
                this.setState({
                    component: <Component {...this.props} />
                });
            }
        }

        render() {
            const Component = this.state.component || <div />;
            //close zendesk if on public page
            this.closeZendeskApp(this.props);
            return (
                <ReactPlaceholder
                    type="text"
                    rows={7}
                    ready={Component !== null}
                >
                    {Component}
                </ReactPlaceholder>
            );
        }
    }
    return AsyncFunc;
}
