import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createBrowserHistory as createHistory } from "history";

import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import reducers from "../redux/reducers";

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];

const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
          })
        : compose;

const store = createStore(
    combineReducers({
        ...reducers,
        router: connectRouter(history)
    }),
    composeEnhancers(applyMiddleware(...middlewares))
);

export { store, history };
