import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import appActions from "../../redux/app/actions";
import TopbarAccounts from "./topbarAccounts";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import ButtonSupportWrapper from "./buttonSupport.style";
import { shouldShowAccountsDropdown } from "../../helpers/utility";
import { withRouter } from "react-router";
import IntlMessages from "../../components/utility/intlMessages";
import BakeryAPI from "../../bakeryAPI";
import Alert from "../../components/uielements/alert";
import LanguageSwitcher from "../LanguageSwitcher/index";
import { injectIntl } from "react-intl";
import IconSuport from "./IconSuport";
import { openZendesk, closeZendesk } from "../../helpers/utility";

import { withZendeskProviderContextApi } from "../../components/zendeskContext.js";

const { Header } = Layout;
const { toggleCollapsed } = appActions;

class Topbar extends Component {
    constructor(props) {
        super(props);

        this.bakeryAPI = new BakeryAPI(this.props);
        this.saveAccountId = this.saveAccountId.bind(this);

        this.state = {
            accounts: [],
            accountSeleted: null,
            userName: "",
            loading: false,
            hasError: false,
            hasErrorOnGetProfile: false,
            errorMessage: "",
            openZD: false
        };
    }

    componentDidMount() {
        const jwt = localStorage.getItem("app")
            ? JSON.parse(localStorage.getItem("app")).jwt
            : "";
        const accountSeletedUid = localStorage.getItem("accountUid");

        if (!jwt) {
            this.props.history.push(`/signin`);
        }

        this.setState({
            loading: true
        });

        this.bakeryAPI
            .getAccounts(jwt)
            .then(res => {
                const accounts = [].concat(res.data);
                const ordererAccountsList = accounts.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );

                this.setState({
                    accounts: ordererAccountsList,
                    loading: false,
                    hasError: false
                });
            })
            .then(() => {
                if (shouldShowAccountsDropdown(this.props.location.pathname)) {
                    this.bakeryAPI
                        .getAccounts(jwt, accountSeletedUid)
                        .then(res => {
                            let accountSeleted = res ? res.data[0] : null;

                            this.setState({
                                accountSeleted,
                                hasError: false
                            });
                        })
                        .catch(() =>
                            this.setState({
                                hasError: true,
                                errorMessage: (
                                    <IntlMessages id="error.getAccounts" />
                                )
                            })
                        );
                }

                this.setState({
                    accountSeleted: null,
                    hasError: false
                });
            })
            .catch(() =>
                this.setState({
                    hasError: true,
                    errorMessage: <IntlMessages id="error.getAccounts" />
                })
            );

        this.bakeryAPI
            .getProfile(jwt)
            .then(res =>
                this.setState({
                    userName: res.data[0].name
                })
            )
            .catch(() =>
                this.setState({
                    userName: "*"
                })
            );

        //listen to events CLOSE and OPEN on zendesk
        window.ttZD.subscribe(this.listenZendeskEvent);
    }

    listenZendeskEvent = event => {
        if (event === "ZENDESK.CLOSE" && this.props.zendeskContext.opened) {
            this.props.zendeskContext.actions.updateOpen(false);
        }

        if (event === "ZENDESK.OPEN" && !this.props.zendeskContext.opened) {
            this.props.zendeskContext.actions.updateOpen(true);
        }
    };

    componentWillUnmount() {
        //remove listener
        window.ttZD.unsubscribe(this.listenZendeskEvent);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const jwt = localStorage.getItem("app")
                ? JSON.parse(localStorage.getItem("app")).jwt
                : "";
            const accountSeletedUid = localStorage.getItem("accountUid");

            this.bakeryAPI
                .getAccounts(jwt)
                .then(res => {
                    const accounts = res ? [].concat(res.data) : [];

                    this.setState({ accounts, hasError: false });
                })
                .then(() => {
                    if (
                        shouldShowAccountsDropdown(this.props.location.pathname)
                    ) {
                        this.bakeryAPI
                            .getAccounts(jwt, accountSeletedUid)
                            .then(res => {
                                let accountSeleted = res ? res.data[0] : null;

                                this.setState({
                                    accountSeleted,
                                    hasError: false
                                });
                            })
                            .catch(() =>
                                this.setState({
                                    hasError: true,
                                    errorMessage: (
                                        <IntlMessages id="error.getAccounts" />
                                    )
                                })
                            );
                    }

                    this.setState({
                        accountSeleted: null,
                        hasError: false
                    });
                })
                .catch(() =>
                    this.setState({
                        hasError: true,
                        errorMessage: <IntlMessages id="error.getAccounts" />
                    })
                );
        }
    }

    saveAccountId(account) {
        localStorage.setItem("accountUid", account.uid);
        this.setState({
            accountSeleted: account
        });
    }

    openSupport(lang) {
        try {
            openZendesk(lang, null, "#2D3446");
            this.props.zendeskContext.actions.updateOpen(true);
        } catch (e) {
            console.log(e);
        }
    }

    closeSupport() {
        try {
            closeZendesk();
            this.props.zendeskContext.actions.updateOpen(false);
        } catch (e) {
            console.log(e);
        }
    }

    getBtLegend() {
        let legend = <IntlMessages id="topbar.support" />;

        return legend;
    }

    render() {
        const {
            accounts,
            accountSeleted,
            userName,
            hasError,
            errorMessage
        } = this.state;
        const { toggleCollapsed, customizedTheme, locale } = this.props;
        const collapsed = this.props.collapsed && !this.props.openDrawer;
        const styling = {
            background: customizedTheme.backgroundColor,
            position: "fixed",
            width: "100%",
            height: 70
        };

        let pageTitle = "";
        if (this.props.current) {
            pageTitle = this.props.intl.formatMessage({
                id: "page.titles." + this.props.current
            });
        }

        return (
            <TopbarWrapper>
                <Header
                    style={styling}
                    className={
                        collapsed
                            ? "isomorphicTopbar collapsed"
                            : "isomorphicTopbar"
                    }
                >
                    <div className="isoLeft">
                        <button
                            className={
                                collapsed
                                    ? "triggerBtn menuCollapsed"
                                    : "triggerBtn menuOpen"
                            }
                            style={{ color: customizedTheme.textColor }}
                            onClick={toggleCollapsed}
                        />
                        <h1 className="title">{pageTitle}</h1>
                    </div>

                    <ul className="isoRight">
                        {hasError && (
                            <Alert type="error" message={errorMessage} />
                        )}

                        {accounts.length > 0 && (
                            <li>
                                <TopbarAccounts
                                    accounts={accounts}
                                    accountSeleted={accountSeleted}
                                    saveAccountId={this.saveAccountId}
                                />
                            </li>
                        )}
                        <li style={{ marginRight: "12px" }}>
                            <ButtonSupportWrapper
                                onClick={() => {
                                    if (!window.ttZD.isOpen()) {
                                        this.openSupport(locale);
                                    } else {
                                        this.closeSupport();
                                    }
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <IconSuport
                                        style={{ marginRight: "12px" }}
                                    />
                                    {this.getBtLegend()}
                                </div>
                            </ButtonSupportWrapper>
                        </li>
                        <li className="isoUser">
                            <LanguageSwitcher />
                        </li>

                        <li className="isoUser">
                            <TopbarUser userName={userName} />
                        </li>
                    </ul>
                </Header>
            </TopbarWrapper>
        );
    }
}

export default injectIntl(
    withRouter(
        connect(
            state => ({
                ...state.App,
                locale: state.LanguageSwitcher.language.locale,
                customizedTheme: state.ThemeSwitcher.topbarTheme
            }),
            { toggleCollapsed }
        )(withZendeskProviderContextApi(Topbar))
    )
);
