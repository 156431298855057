import antdPt from "antd/lib/locale-provider/pt_BR";
import appLocaleData from "react-intl/locale-data/pt";
import ptMessages from "../locales/pt_BR.json";

const PtLang = {
  messages: {
    ...ptMessages
  },
  antd: antdPt,
  locale: "pt-BR",
  data: appLocaleData
};
export default PtLang;
