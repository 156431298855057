const actions = {
    GET_USERS_API_STATUS: "GET_USERS_API_STATUS",
    GET_USERS_API_FETCHING_STATUS: "GET_USERS_API_FETCHING_STATUS",
    GET_CONSENT_API_STATUS: "GET_CONSENT_API_STATUS",
    GET_CONSENT_API_FETCHING_STATUS: "GET_CONSENT_API_FETCHING_STATUS",
    getUserApiStatus: status => ({
        type: actions.GET_USERS_API_STATUS,
        status
    }),
    getUserApiIsFecthing: isFetching => ({
        type: actions.GET_USERS_API_FETCHING_STATUS,
        isFetching
    }),
    getConsentApiStatus: status => ({
        type: actions.GET_CONSENT_API_STATUS,
        status
    }),
    getConsentApiIsFecthing: isFetching => ({
        type: actions.GET_USERS_API_FETCHING_STATUS,
        isFetching
    })
};

export default actions;
