import React, { Component } from "react";

let ZendeskContext = React.createContext({});

class ZendeskProvider extends Component {
    constructor(props) {
        super(props);

        this.state = this.initialState();
    }

    initialState() {
        return {
            opened: false,

            actions: {
                updateOpen: value => {
                    this.setState(function(prevState, prervProps) {
                        let _newState = { ...prevState };
                        _newState.opened = value;

                        return _newState;
                    });
                }
            }
        };
    }

    render() {
        return (
            <ZendeskContext.Provider value={this.state}>
                {this.props.children}
            </ZendeskContext.Provider>
        );
    }
}

export const ZendeskContextConsumer = ZendeskContext.Consumer;

export const withZendeskProviderContextApi = Component => {
    return props => (
        <ZendeskContext.Consumer>
            {value => <Component {...props} zendeskContext={value} />}
        </ZendeskContext.Consumer>
    );
};

export default ZendeskProvider;
