import Enlang from "./entries/en-US";
import Ptlang from "./entries/pt-BR";
import Eslang from "./entries/es-ES";
import { addLocaleData } from "react-intl";

const AppLocale = {
  en: Enlang,
  pt: Ptlang,
  es: Eslang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.es.data);

export default AppLocale;
