import React, { Component } from "react";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import { Link } from "react-router-dom";
import { clearToken, getInitials } from "../../helpers/utility";
import { withRouter } from "react-router";
import BakeryAPI from "../../bakeryAPI";

class TopbarUser extends Component {
    constructor(props) {
        super(props);

        this.bakeryAPI = new BakeryAPI(this.props);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hide = this.hide.bind(this);
        this.state = {
            visible: false
        };
    }
    handleLogout() {
        const jwt = localStorage.getItem("app")
            ? JSON.parse(localStorage.getItem("app")).jwt
            : "";
        this.bakeryAPI.logout(jwt).then(() => {
            clearToken();
            this.props.history.push("/signin");
        });
    }
    hide() {
        this.setState({ visible: false });
    }
    handleVisibleChange() {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const { userName } = this.props;

        const content = (
            <TopbarDropdownWrapper className="isoUserDropdown">
                <div onClick={this.hide}>
                    <Link className="isoDropdownLink" to={"/cmp/accounts"}>
                        <IntlMessages id="topbar.viewAccounts" />
                    </Link>
                </div>
                <div onClick={this.hide}>
                    <Link
                        className="isoDropdownLink"
                        to={"/cmp/profileSettings"}
                    >
                        <IntlMessages id="topbar.security" />
                    </Link>
                </div>
                <div onClick={this.hide}>
                    <Link
                        className="isoDropdownLink"
                        to={"/cmp/changePassword"}
                    >
                        <IntlMessages id="topbar.changePassword" />
                    </Link>
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className="isoDropdownLink"
                    onClick={e => this.handleLogout(e)}
                >
                    <IntlMessages id="topbar.logout" />
                </div>
            </TopbarDropdownWrapper>
        );

        return (
            <Popover
                content={content}
                trigger="click"
                visible={this.state.visible}
                onVisibleChange={this.handleVisibleChange}
                arrowPointAtCenter={true}
                placement="bottomLeft"
            >
                <div className="isoImgWrapper">
                    {getInitials(userName)}
                    <span className="userActivity online" />
                </div>
            </Popover>
        );
    }
}
export default withRouter(TopbarUser);
