import styled from "styled-components";
import { palette } from "styled-theme";
import WithDirection from "../../settings/withDirection";

const ButtonSupportWrapper = styled.button`
    padding: 4px 12px;
	background: #FFFFFF;
	border 0px solid #FFFFFF;
	border-radius:6px;
	&:hover {
		background-color: ${palette("secondary", 6)};
	}
`;

export default WithDirection(ButtonSupportWrapper);
