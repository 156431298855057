import React from "react";

function IconSuport({ style }) {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            style={style ? style : {}}
        >
            <path
                d="M8.5 0.5C3.8131 0.5 0 4.3131 0 9V12.5216C0 13.392 0.76245 14.1 1.7 14.1H2.55C2.77543 14.1 2.99163 14.0104 3.15104 13.851C3.31045 13.6916 3.4 13.4754 3.4 13.25V8.87845C3.4 8.65302 3.31045 8.43681 3.15104 8.27741C2.99163 8.118 2.77543 8.02845 2.55 8.02845H1.7782C2.2508 4.73895 5.0813 2.2 8.5 2.2C11.9187 2.2 14.7492 4.73895 15.2218 8.02845H14.45C14.2246 8.02845 14.0084 8.118 13.849 8.27741C13.6896 8.43681 13.6 8.65302 13.6 8.87845V14.1C13.6 15.0375 12.8376 15.8 11.9 15.8H10.2V14.95H6.8V17.5H11.9C13.7751 17.5 15.3 15.9751 15.3 14.1C16.2376 14.1 17 13.392 17 12.5216V9C17 4.3131 13.1869 0.5 8.5 0.5Z"
                fill="#4B4B4B"
            />
        </svg>
    );
}

export default IconSuport;
