export const getKeysList = translationsMounted => {
    let keysList = [];
    for (let index in translationsMounted) {
        keysList = [...keysList, translationsMounted[index].keyId];
    }

    return keysList;
};

export const passwordPoliciesValidation = (policies, password) => {
    let fails = [];
    for (var i = 0; i < policies.length; i++) {
        const regex = new RegExp(policies[i].regex);
        if (!regex.test(password)) {
            fails.push(policies[i].policy);
        }
    }

    return fails;
};

export const emailValidationRules = email => {
    let failedEmail = "";

    if (email === "") {
        failedEmail = "EMAIL_EMPTY";
    }

    return failedEmail;
};

export const nameValidationRules = email => {
    let failedEmail = "";

    if (email === "") {
        failedEmail = "NAME_EMPTY";
    }

    return failedEmail;
};

export const roleValidationRules = email => {
    let failedRole = "";

    if (email === "") {
        failedRole = "ROLE_EMPTY";
    }

    return failedRole;
};

export const accountNameValidationRules = accountName => {
    let failedAccountName = "";

    if (accountName === "") {
        failedAccountName = "INVALID_ACCOUNT_NAME";
    }

    return failedAccountName;
};

export function clearToken() {
    localStorage.removeItem("app");
    localStorage.removeItem("accountUid");
}

export function rmRedirectTo() {
    sessionStorage.removeItem("redirectTo");
}

export function setRedirectTo(url) {
    //url /oauth/auth dont use this logic of redirect
    if (url.indexOf("/oauth/auth") === -1) {
        sessionStorage.setItem("redirectTo", url);
    }
}

export function getRedirectTo(url) {
    return sessionStorage.getItem("redirectTo");
}

export function getInitials(str) {
    const words = str.split(" ");
    if (words.length > 1) {
        return `${words[0].charAt(0)}${words[1].charAt(0)}`.toUpperCase();
    }

    return `${words[0].charAt(0)}${words[0].charAt(1)}`.toUpperCase();
}

export function isLogged(params) {
    let isLoggedIn = false;

    try {
        let logged = JSON.parse(localStorage.getItem("app"));

        if (logged && (logged.jwt === "true" || logged.jwt === true)) {
            isLoggedIn = true;
        }
    } catch (e) {
        console.log(e);
        isLoggedIn = false;
    }

    return isLoggedIn;
}

export const shouldShowAccountsDropdown = pathname => {
    switch (pathname) {
        case "/cmp/accounts":
        case "/cmp/profileSettings":
        case "/cmp/changePassword":
            return false;
        default:
            return true;
    }
};

export const handleToken = (response, email) => {
    if (response && response.code === 401) {
        clearToken();
    }

    if (response && (response.code === 202 || response.code === 203)) {
        localStorage.setItem("app", JSON.stringify({ jwt: "true" }));
    }
};

export const openZendesk = (lang, position, color) => {
    try {
        if (window.ttZD && window.zE) {
            window.ttZD.openZendesk(
                lang ? lang : null,
                position ? position : null,
                color ? color : null
            );
        }
    } catch (e) {
        console.log("Error: " + e);
    }
};

export const closeZendesk = () => {
    try {
        if (window.ttZD && window.zE) {
            window.ttZD.closeZendesk();
        }
    } catch (e) {
        console.log("Error: " + e);
    }
};

export const setLangOnButton = lang => {
    try {
        if (window.ttZD && window.zE) {
            window.ttZD.setLangOnButton(lang);
        }
    } catch (e) {
        console.log("Error: " + e);
    }
};
