import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { connect } from "react-redux";
import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";
import {
    setRedirectTo,
    // rmRedirectTo,
    // getRedirectTo,
    isLogged
} from "./helpers/utility";

const RestrictedRoute = ({ component: Component, isLoggedIn, tk, ...rest }) => {
    // console.log("@@@ RestrictedRoute", isLogged());

    if (!isLoggedIn) {
        //if not logged and user is acessing  this area we save the path url to redirect him after login and select account
        setRedirectTo(window.location.pathname);
    }

    // if logged render the page, else redirects to singin
    return (
        <Route
            {...rest}
            render={props =>
                isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/signin",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};
const PublicRoutes = ({ history, isLoggedIn }) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <Switch>
                    <Route
                        exact
                        path={"/"}
                        component={asyncComponent(() =>
                            import("./containers/Page/signin")
                        )}
                    />
                    <Route
                        exact
                        path={"/404"}
                        component={asyncComponent(() =>
                            import("./containers/Page/404")
                        )}
                    />
                    <Route
                        exact
                        path={"/500"}
                        component={asyncComponent(() =>
                            import("./containers/Page/500")
                        )}
                    />
                    <Route
                        exact
                        path={"/signin"}
                        component={asyncComponent(() =>
                            import("./containers/Page/signin")
                        )}
                    />
                    <Route
                        exact
                        path={"/mfa"}
                        component={asyncComponent(() =>
                            import("./containers/Page/mfa")
                        )}
                    />
                    <Route
                        exact
                        path={"/signup"}
                        component={asyncComponent(() =>
                            import("./containers/Page/signup")
                        )}
                    />
                    <Route
                        exact
                        path={"/forgotpassword"}
                        component={asyncComponent(() =>
                            import("./containers/Page/forgotPassword")
                        )}
                    />
                    <Route
                        exact
                        path={"/resetpassword"}
                        component={asyncComponent(() =>
                            import("./containers/Page/resetPassword")
                        )}
                    />
                    <Route
                        exact
                        path={"/emailConfirmation"}
                        component={asyncComponent(() =>
                            import("./containers/Page/emailConfirmation")
                        )}
                    />
                    <Route
                        exact
                        path={"/oauth/auth"}
                        component={asyncComponent(() =>
                            import("./containers/Page/oauth")
                        )}
                    />
                    <Route
                        exact
                        path={"/terms-of-use"}
                        component={asyncComponent(() =>
                            import("./containers/Page/termsOfUse")
                        )}
                    />
                    <RestrictedRoute
                        path="/cmp"
                        component={App}
                        isLoggedIn={isLoggedIn}
                    />
                    <Route
                        path={"*"}
                        component={asyncComponent(() =>
                            import("./containers/Page/404")
                        )}
                    />
                </Switch>
            </div>
        </ConnectedRouter>
    );
};

export default connect(state => {
    let logged = isLogged();
    return {
        // isLoggedIn: state.Auth.idToken !== null,
        // isLoggedIn: state.idToken !== null,
        isLoggedIn: logged,
        tk: state.idToken
    };
})(PublicRoutes);
