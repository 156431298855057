import actions from "./actions";

const initState = {
    getUsersApiStatus: "",
    getUserApiIsFecthing: false,
    consentApiStatus: ""
};

export default function appReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_USERS_API_STATUS:
            return {
                ...state,
                getUsersApiStatus: action.status
            };
        case actions.GET_USERS_API_FETCHING_STATUS:
            return {
                ...state,
                getUserApiIsFecthing: action.isFetching
            };
        case actions.GET_CONSENT_API_STATUS:
            return {
                ...state,
                consentApiStatus: action.status
            };
        default:
            return state;
    }
}
